.block-professors,
.block-authorities {
    
    a {
        color: $black;
        
        display: flex;
        align-items: center;
        padding-left: 0;

        &:hover {
            background: transparent;
        }

        img {
            display: block;
            max-height: 6rem;
            width: 6rem;
            border-radius: 50%;
            margin: 0 $gap 0 0;
        }

        h4 {
            
            font-weight: bold;
            font-family: $family-secondary;
        }

        span {
            color: $black;
            font-weight: 400;
        }
    }
}