.navbar-burger {
    background: $dark-cyan;
    display: flex;
    border-radius: 50%;
    margin-left: 0;
    height: 3.125rem;
    width: 3.125rem;
    z-index: 1;

    &:hover {
        background: $dark-cyan;
    }

    span {
        background: $white;
        border-radius: 2px;
        left: calc(50% - .675rem);
        height: 2px;
        width: 1.333rem;
    }
}

.is-context-menu {

    .navbar-burger {
        background: $white;

        span {
            background: $black;
        }
    }
}

.burger-label {
    color: $dark-cyan;
    display: none;
    margin-left: $gap / 2;

    @include tablet {
        display: block;
    }
}