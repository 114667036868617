.search {
    margin-left: $gap * 2;

    span {
        visibility: hidden;
    }

    svg {
        position: relative;
        top: $gap / 1.7;
        left: $gap / 1.7;
        
        .search-btn {
            fill: $white;
        }
    }
}

.is-context-menu {

    .search {
        background: $white;

        &.is-active {

            span {
                visibility: visible;
            }

            svg {
                visibility: hidden;
            }
        }

        svg {

            .search-btn {
                fill: $black;
            }
        }
    }
}