.footer-link {
    background-color: $dark-cyan;
    display: none;

    @include tablet {
        display: block;
    }

    .container {
        padding: 0.75rem 0;
    }

    hr {
        background-color: $grey;
        display: block;
        height: 1px;
    }

    h3 {
        color: $white;
        
    }

    ul {
        display: flex;
        flex-wrap: wrap;
        flex-direction: column;
        max-height: 10rem;

        li {
            width: auto;
            
            a {
                color: $white;
                font-weight: 300;
            }
        }
    }
}

.footer {
    background-color: $dark-cyan;
    padding: 1.5rem;
    margin: 0;

    @include desktop {
        padding: 3.5rem;
    }

    .container {

        .columns {
            align-items: center;

            &:not(:last-child) {
                margin-bottom: 0;

                @include desktop {
                    margin-top: $gap;
                }
            }
        }

        .brand {

            img {
                height: $gap * 3.5;
            }
        }

        .logo {

            img {
                height: $gap * 3.5;

            }

            .logo-mobile{

                img{
                    height: auto;
                    width:70%;
                }

            }
        }

        .cr {
            color: $white;
            
            font-weight: 300;
            text-align: right;

            @include tablet {
                
                font-weight: 400;
            }

            @include desktop {
                
            }
        }

        .mobile-social {

            @include desktop {
                display: none;
            }

            .column {
                border-top: 1px solid $white;

                .social {
                    justify-content: center;
                }
            }
        }
    }
}


