.block-dependencies-in-body {
    background: $grey-thin;
    height: auto !important;
    .card-content {
        padding: 0;

        @include tablet {
            padding: $card-content-padding;
        }

        a {
            color: $black;

            &:hover {
                color: var(--color);
            }
        }
    
        .media {
            display: block;
    
            @include desktop {
                display: flex;
                align-items: center;
            }

            .media-left {
                margin-right: 0;
                
                @include desktop {
                    margin-right: $gap;
                }
        
                figure {
        
                    @include tablet {
                        height: 128px;
                        width: 128px;
                    }
                    
                    img {
                
                        @include tablet {
                            border-radius: 50%;
                        }
                    }
                }
            }

            .media-content {
                padding: $gap;

                @include desktop {
                    padding: 0;
                }
            }

            .media-right {
                margin-left: 0;
                padding: 0 $gap $gap;
                
                @include desktop {
                    margin-left: $gap;
                    padding: 0;
                }

                a {
                    background: $dark-cyan;
                    border-radius: 6px;
                    box-shadow: 4px 4px 4px rgba($black, .16);
                    color: $white;
                    display: inline-block;
                    font-weight: 500;
                    padding: $gap / 2 $gap;
                    text-transform: uppercase;

                    &:after {
                        background: url(../img/a-dependences-body-arrow.svg) no-repeat center;
                        background-size: contain;
                        content: "";
                        display: inline-block;
                        height: $gap;
                        width: $gap;
                    }
                }
            }
        }
    }
}