.course_plan {
    margin-left: 8rem;

    .block-title {
        margin-bottom: $gap * 1.5;

        h2, h3, h4, h5, h6 {
            color: var(--color);
            text-transform: uppercase;
        }
    }

    .year {
        margin-left: 5rem;

        .quarter {
            // margin-left: 5rem;

            a {
                color: grey;

                &:hover {
                    color: $dark-cyan;
                    font-weight: bold;
                }
            }
        }
    }
}

.course_plan {

    .title {
        font-weight: bold;
        margin-bottom: 0;
    }
}