.hero-body{
    padding-bottom: 0;
}

.event-link {
    display:inline-block;
    padding:$gap/2 $gap;
    margin:0 $gap/2;
    border-radius:0.2em;
    box-sizing: border-box;
    text-decoration:none;
    font-family:'Roboto',sans-serif;
    font-weight:400;
    color:#FFFFFF;
    background-color:#3369ff;
    box-shadow:inset 0 -0.6em 1em -0.35em rgba(0,0,0,0.17),inset 0 0.6em 2em -0.3em rgba(255,255,255,0.15),inset 0 0 0em 0.05em rgba(255,255,255,0.12);
    text-align:center;
    position:relative;

    &.youtube{
        background: #FF0000;
    }
    &.meet{
        background: #139e91;
    }
    &.zoom{
        background:#2D8CFF;
    }
    &.jitsi{
        background: #0f4aa7;
    }

}



