.block-direct_access {

    .menu-list {

        li {

            a {
                align-items: center;
                display: flex;
                padding: $gap / 2 0;
                color: $blue-sky;

                &:hover {
                    background: transparent;

                    i {
                        transform: rotate(-45deg);
                    }
                }

                i {
                    transition: all 0.3s;
                    background: url(../img/direct-access.svg) no-repeat center;
                    background-size: contain;
                    margin-left: 5px;
                    height: $gap;
                    width: $gap;
                    flex: none;
                }
            }
        }
    }
}