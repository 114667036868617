#navSuggested {
    margin: 0 $gap $gap;
    position: relative;

    @include tablet {
        margin: 50px $gap*2 $gap;
    }

    @include desktop {
        width: 85%;
        margin: 50px auto 0;
    }

    .suggested_links {
        display: flex;
        flex-flow: row wrap;
    
        li {
            margin-bottom: $gap * 2;
            flex: none;
            width: 100%;

            @include tablet {
                width: 100%;
            }
    
            a {
                font-family: $family-secondary;
                color: $white;
                align-items: center;
                display: flex;
                flex-flow: row wrap;

                &:hover {
                    color: $white;
                }
                
                figure {
                    border-radius: 50%;
                    margin-right: $gap;
                    overflow: hidden;
                    height: 8rem;
                    width: 8rem;

                    @include tablet {
                        height: 10rem;
                        width: 10rem;
                    }
    
                    img {
                        object-fit: contain;
                        height: auto;
                        width: 100%;
                    }
                }
    
                .item-inner{
                    width: 17rem;

                    h3 {
                        background: $gold;
                        color: $dark-cyan;
                        font-weight: bold;
                        padding: 2px $gap;
                        position: relative;
                        margin-bottom: $gap * 4;
                        margin-left: -$gap * 4;
                        text-align: center;
                        width: 90%;

                        @include tablet {
                            margin-bottom: $gap;
                        }
                    }

                    p {
                        display: none;

                        @include tablet {
                            display: block;
                        }
                    }
                }
            }
        }
    }
}