figure {
    position: relative;
    display: block;
    overflow: hidden;

    .is-rounded {
        position: relative;
        margin: 1px;
        z-index: 2;
    }

    .circle {
        position: absolute;
        z-index: 1;
        top: 0;
        left: 0;
        display: block;
        width: 100%;
        height: 100%;
        background: rgba(255,255,255,.1);
        border-radius: 100%;

        &:after {
            background: transparent;
            border-radius: 100%;
            content: '';
            position: absolute;
            top: 2px;
            left: 2px;
            width: 36px;
            height: 36px;
        }

        span {
            position: absolute;
            width: 50%;
            height: 100%;
            overflow: hidden;

            em {
                position: absolute;
                border-radius: 999px;
                width: 100%;
                height: 100%;
                background: $gold;
                transition: transform .25s linear;
            }      

            &:first-child {
                left: 0%;

                em {
                    left: 100%;
                    border-top-left-radius: 0;
                    border-bottom-left-radius: 0;
                    transform-origin: 0% 50%;
                }
            }

            &:last-child {
                left: 50%;

                em {
                    left: -100%;
                    border-top-right-radius: 0;
                    border-bottom-right-radius: 0;
                    transform-origin: 100% 50%;
                    transition-delay: .25s;
                }
            }
        }
    }

    &:hover {

        .circle span {

            &:first-child em {
                transform: rotate(-180deg);
                transition: transform .5s ease-out;
                transition-delay: .25s;
            }

            &:last-child em {
                transform: rotate(-180deg);
                transition: transform .25s linear;
            }
        }
    }
}