.nav-layout {
    background: $dark-cyan;
    display: none;
    opacity: 0;
    position: fixed;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    transition: all 0.5s ease;

    @include desktop {
        width: 60%;
    }
    
    #navbar {
        background: transparent;
        box-sizing: border-box;
        margin-top: 120px;
        position: relative;
        min-height: 25vh;

        @include desktop {
            width: 85%;
            margin: 25vh auto 50px;
        }
        
        .navbar-menu {
            background: transparent;
            box-shadow: none;
            display: block;
            flex-flow: column;

            .navbar-start {
                flex-flow: column;
                position: relative;
                width: 100%;

                @include tablet {
                    height: 100%;
                    width: 33%;
                }
            }
        }

        .navbar-item {
            font-family: $title-family;
            padding: 0 $gap $gap;
            white-space: initial;
            width: 100%;
                
            &.is-active {
                background: $dark-cyan;
                color: $yellow;

                &:hover {
                    color: $white;
                }
            }

            &.is-parent {
                display: flex;
            }

            &.is-hoverable {

                &:hover {

                    .navbar-dropdown {
                        display: none;
                    }

                    > .navbar-dropdown {
                        display: block;
                        opacity: 1;
                    }
                }
            }

            .navbar-link {
                display: flex;
                align-items: center;
                padding: 0.5rem 0.75rem 0.5rem 0;
                width: 100%;
            }

            figure {
                display: inline-table;
                margin-right: $gap / 2;
                height: 32px;
                width: 32px;

                .is-rounded {
                    max-height: 100%;
                    height: 30px;
                    width: 30px;
                }
            }

            .is-parent {

                figure {
                    height: 24px;
                    width: 24px;

                    .is-rounded {
                        height: 22px;
                        width: 22px;
                    }
                }

                span {
                    display: block;
                }
            }

            .navbar-back {
                display: block;
                width: 20px;

                @include desktop {
                    display: none;
                }

                i {
                    background: url(../img/menu-item-has-dropdown.svg) no-repeat center;
                    background-size: contain;
                    display: block;
                    transform: rotate(180deg);
                    height: 100%;
                    width: 0.425em;
                }
            }


            .is-arrowless {
                font-weight: bold;
            }
        }
        
        .has-dropdown {
            position: initial;

            &.hover-dropdown {

                > .navbar-dropdown {
                    display: block;
                }
            }

            .navbar-link {

                @include desktop {
                    padding: 0 0.75em 0 0;
                }
            }
            
            > .navbar-link {
                    
                &:after {
                    background: url(../img/menu-item-has-dropdown.svg) no-repeat center;
                    background-size: contain;
                    border: none;
                    transform: rotate(0deg);
                    right: 0;
                }
            }

            .navbar-dropdown {
                background: $dark-cyan;
                border-radius: 0;
                box-shadow: none;
                font-size: 1.250rem;
                padding: 20vh 0 0;
                position: fixed;
                left: 100%;
                top: 0;
                width: 100% !important;
                transition: left .2s;
                height: 100vh;
                z-index: 3;

                @include desktop {
                    background: transparent;
                    opacity: 0;
                    padding: 0;
                    position: absolute;
                    height: 100%;
                    width: 33% !important;
                }

                &:before {

                    @include desktop {
                        background: $gold;
                        content: "";
                        left: -1px;
                        position: absolute;
                        height: 100%;
                        width: 1px;
                    }
                }

            }

            .has-dropdown {
                position: initial;
        
                .has-dropdown {
                    position: absolute;
                    left: 100%;
                    top: 0;

                    @include desktop {
                        display: none;
                    }
                }
        
                &:hover {
                    
                    .has-dropdown {
                        display: block;
                    }
                }
            }

            &.show-dropdown {

                > .navbar-dropdown {
                    left: 0;

                    > .is-parent {

                        .navbar-link {
                            display: block;
                            text-align: center;

                            figure {
                                margin: 0 auto;
                                height: 68px;
                                width: 68px;

                                .circle span {

                                    &:first-child em {
                                        transform: rotate(-180deg);
                                        transition: transform .5s ease-out;
                                        transition-delay: .25s;
                                    }
                        
                                    &:last-child em {
                                        transform: rotate(-180deg);
                                        transition: transform .25s linear;
                                    }
                                }

                                .is-rounded {
                                    height: 66px;
                                    width: 66px;
                                }
                            }
                        }

                        .navbar-back {
                            position: absolute;
                            height: 28px;
                            z-index: 2;
                        }
                    }
                }
            }
        }
    }
}


.has-dropdown {

    :before {
        content: "";
        transition: width .3s ease;
        position: absolute;
        width: 0;
    }
                
    &.hover-dropdown {

        > .navbar-dropdown {

            > .is-parent {
                position: relative;

                &:before {
                    display: none;
                }
    
                .circle span {
    
                    &:first-child em {
                        transform: rotate(-180deg);
                        transition: transform .5s ease-out;
                        transition-delay: .25s;
                    }
        
                    &:last-child em {
                        transform: rotate(-180deg);
                        transition: transform .25s linear;
                    }
                }
            }
        }

        .navbar-item {
            
            &:hover {
                position: relative;

                &:before {
                    transition: width .3s ease;
                    content: "";
                    background: $gold;
                    top: $gap * 2 - 2px;
                    position: absolute;
                    height: 1px;
                    width: 10%;
                }
            }

            &.has-dropdown {
                border-bottom: none;
            }
        }
    }

    &.navbar-item {

        &:before {
            display: none;
        }
    }
}
