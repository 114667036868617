.shortcuts {

    .guarani .image-inner {
        background: url("../img/guarani.svg") no-repeat center;
    }
    .aulavirtual .image-inner {
        background: url("../img/aulavirtual.svg") no-repeat center;
    }
    .agenda .image-inner {
        background: url("../img/agenda.svg") no-repeat center;
    }
    .cursos .image-inner {
        background: url("../img/icono-cursoydiplomatura-01.svg") no-repeat center;
    }
    .movilidad .image-inner {
        background: url("../img/movilidad.svg") no-repeat center;
    }
    .carreras .image-inner {
        background: url("../img/carreras.svg") no-repeat center;
    }

    .column {

        .image {
            align-items: center;
            background-color: #E8E8E8;
            display: flex;
            justify-content: center;
            height: 4.25rem;

            @include desktop {
                height: 8.5rem;
            }
            
            .image-inner {
                background-size: contain;
                max-height: 2.9375rem;
                max-width: 2.9375rem;
                height: 100%;
                width: 100%;
                
                @include desktop {
                    max-height: 5.875rem;
                    max-width: 5.875rem;
                }
            }
        }

        a {
            align-items: center;
            background-color: #F4F4F4;
            color: $grey-dark;
            display: flex;

            text-align: center;
            line-height: 1;
            justify-content: center;
            height: 3.25rem;
        }
    }

    .short-link {
        display: flex;
        flex-flow: column;
        justify-content: space-around;

        .image {
            display: none;
        }

        a {
            background-color: $grey;
            color: $white;

            margin-bottom: 3%;
            min-height: 1.125rem;
            text-transform: uppercase;
            width: 100%;
            height: 30%;

            @include desktop {
                min-height: 2.25rem;
            }

            &:hover {
                background: $dark-cyan;
                color: $grey-dim;
            }

            &:last-child {
                margin-bottom: 0;
            }
        }
    }
}