.listings {

    h3 {
        
        color: $dark-cyan;

        @include desktop {
            
        }
    }

    .level-item {
        
        &.see-all {

            a {
                align-items: center;
                color: $dark-cyan;
                display: flex; 
                
                font-weight: bolder;
                text-transform: uppercase;
                letter-spacing: 2px;
                line-height: 1.9rem;

                @include desktop {
                    

                }

                circle {
                    fill:$dark-cyan;
                }

                svg, img {
                    height: 1.313rem;
                    margin-left: $gap;

                    @include desktop {
                        height: 1.875rem;
                    }
                }
            }
        }
    }
}