.counselors {

    .content {

        hr {

            &:last-child {
                display: none;
            }
        }

        h3.title {
            color: $dark-cyan;
            font-weight: bold;
            
            @include desktop {
                
            }
        }
    }

    .sidebar {

        a {
            display: block;
        }
    }
}

.block-counselor {
    align-items: center;

    &.order-4 {
        align-items: flex-end;
    }

    .is-counselor {
        position: relative;

        &.decano {
            
            @include desktop {
                max-width: 40%;
            }

            .position {
                background: $dark-cyan;
            }
        }

        &.vicedecana {
            
            @include desktop {
                max-width: 35%;
            }
        }

        &.decano, &.vicedecana {

            .position {

                @include desktop {
                    
                    top: $gap * 2;
                }
            }

            .title {
                width: 80%;
                
                @include desktop {
                    
                }
            }
        }

        &.titular {

            @include desktop {
                width: 14rem;
            }
        }

        &.suplente {
            margin-left: -$gap * 4;
            width: 10rem;
            margin-left: -39px;

            .image {
                margin: 0 auto;
            }

            .position {
                background: $gold;
                box-sizing: border-box;
                
                position: absolute;
                top: 14px;
                left: 30%;
                text-align: center;
                width: 70%;
            }

            .title {
                
            }
        }

        .position {
            background: $dark-cyan;
            color: $white;
            text-transform: uppercase;
            padding: 0 $gap;
            position: absolute;
            right: $gap;
            top: $gap;
        }

        h4.title {
            font-weight: 600;
            
            text-align: center;
            margin: $gap auto 0;
            height: $gap * 3;
            width: 60%;

            a {
                color: $black;
                word-break: initial;
            }
        }
    }
}