.new {

    .hero {

        .hero-body {


            &.is-first {
                padding-top: 0;
            }
        }
    }

}