.table{
    display:block;
    margin: 0 !important;

    .row{
        border-bottom: solid 1px silver;
        padding: $gap 0 ;
        h3, h3 a{
            color: $grey-dark;
        }
        .contact{
            display: flex;
            align-items: center;
            .icon{

                    color: $dark-cyan;

            }
        }
    }
}
.sidebar{
    .search{
        margin: 0;
        #contact-input {
            width: 100%;
            height: 3rem;
            display: inline-block;
            border: 1px solid #ccc;
            border-radius: 4px;
            box-sizing: border-box;
          }
    }
}