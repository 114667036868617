.gallery {
    margin: 0 auto;
    max-width: calc(100vw - 24px);
    height: 60vh;
    width: 100%;

}

.glide__track {
    padding: 0;
    height: 100%;

    .glide__slides {
        display: flex;
        z-index: 0;
        padding: 0;
        margin: 0;
        height: 100%;

        .glide__slide {
            position: relative;
            overflow: hidden;
            padding: 0;
            margin: 0;
            height: 100%;

            figure {
                height: 100%;
                margin: 0;

                .responsive-object {
                    padding-bottom: 0 !important;;
                    position: relative;
                    height: 100%;

                    object,
                    embed,
                    iframe {
                        position: absolute;
                        top: 0;
                        left: 0;
                        width: 100%;
                        height: 100%;
                    }
                }

                img {
                    min-width: 100%;
                    object-fit: cover;
                    min-height: 92%;
                    position: absolute;
                    top: 46%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                }

                figcaption {
                    left: 0;
                    bottom: 0;
                    background: $white;
                    box-sizing: border-box;
                    padding: 0 4px;
                    display: flex;
                    text-align: left;
                    justify-content: space-between;
                    position: absolute;
                    width: 100%;
                    z-index: 4;

                    span {
                        font-size: 11px;
                    }
                }
            }
        }
    }
}