.profile {

    .content {
        color: $grey-dark;
    }

    a {
        color: $black;
    }
    
    a[href*=".pdf"] {
        display: inline-flex;
        padding: inherit;
        margin: inherit;

        &:after {
            display: none;
        }
    }

    .button {
        &:hover {
            background: transparent;
        }
    }
}

.section{
    padding: 4rem 1.5rem;
}

.vdivisor{
    border-left: 1px solid silver;
}