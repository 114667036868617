.hover-dropdown {

    > .navbar-link {
        position: relative;

        .circle span {

            &:first-child em {
                transform: rotate(-180deg);
                transition: transform .5s ease-out;
                transition-delay: .25s;
            }

            &:last-child em {
                transform: rotate(-180deg);
                transition: transform .25s linear;
            }
        }
    }

    .navbar-item {
        
        &:hover {
            position: relative;

            &:before {
                transition: width .3s ease;
                content: "";
                background: $gold;
                top: $gap * 2;
                position: absolute;
                height: 1px;
                width: 10%;
            }
        }

        &.has-dropdown {
            border-bottom: none;
        }
    }
}