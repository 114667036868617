.block-slider{

}

.block-slider, .home-slide {
  
    .gallery, .slider {
        
        margin: 0 auto;
        max-width: calc(100vw - 24px);
        height: 60vh;
        width: 100%;

        .glide__slide {
            position: relative;
            
            .bg-image {
                height: 100%;

                &.overlay {
                    position: relative;

                    &:after {
                        background: rgba($dark-cyan, .65);
                        content: "";
                        position: absolute;
                        top: 0;
                        right: 0;
                        bottom: 0;
                        left: 0;
                    }
                }

                .image{
                    height: 100%;

                    img {
                        min-height: 100%;
                        object-fit: cover;
                    }
                }
            }
    
            .content-image {
                box-sizing: border-box;
                display: flex;
                flex-flow: column;
                align-items: center;
                justify-content: center;
                padding: $gap * 2 $gap * 6; 
                position: absolute;
                top: 0; 
                right: 0;
                bottom: 0;
                left: 0;

                .title,
                .rich-text, 
                .subtitle {
                    color: $white;
                    text-shadow: 3px 2px 3px rgba($black, .35);
                    text-align: center;
                }

            }
        }
    }
}

.home-slide {
    margin-bottom: 4.6875rem;

    &:before {

        @include desktop {
            background: url(../img/bg-slider.svg) no-repeat top right;
            content: "";
            position: absolute;
            max-width: 136.96px;
            right: 0;
            top: 0;
            height: 100%;
            width: 100%;
            z-index: 1;
        }
    }

    .slider {
        //height: initial;
        max-width: 100vw;

        .glide__arrows {
            position: absolute;
            left: 0;
            right: 0;
            top: 50%;
        }
    }
}

.glide__arrows {

    .glide__arrow {
        border: none;
        box-shadow: none;
        height: $gap * 3;
        width: $gap * 3;

        i {
            display: block;
            height: $gap * 2;
            width: $gap;
        }
        
        &.glide__arrow--left {
            left: $gap;

            i {
                background: url(../img/arrow-left.svg) no-repeat center;
                background-size: contain;
            }
        }
    
        &.glide__arrow--right {
            right: $gap;

            i {
                background: url(../img/arrow-right.svg) no-repeat center;
                background-size: contain;
            }
        }
    }
}

.glide__bullets {
    bottom: -$gap * 2;

    @include tablet {
        bottom: $gap * 2;
    }

    .glide__bullet {
        background-color: rgba($dark-cyan, .5);

        @include tablet {
            background-color: rgba($white, .5);
        }

        &.glide__bullet--active {
            background-color: $dark-cyan;

            @include tablet {
                background-color: $white;
            }
        }
    }
}