.block-title {
  margin-bottom: $gap * 1.5;

  h2,
  h4 {
    color: var(--color);
    font-weight: bold;
  }
  .icon {
    color: var(--color);
    margin: 0 $gap;
  }
  .collapsible {
    align-items: center;
    cursor: pointer;
    display: flex;
    transition: 0.3s;
    .icon {
      transition: 0.3s;
    }
    h2 {
      margin: 0;
    }
  }

  &.is-up {
    .icon {
      transform: rotate(180deg);
    }
  }
}

[class*="block-"].is-closed {
  margin: 0;
}
