// Generales
@import 'general/fonts';
@import 'general/variables';
@import 'general/animations';

// Librerías
@import '~bulma';
@import '~bulma-tooltip';

@import 'node_modules/@glidejs/glide/src/assets/sass/glide.core';
@import 'node_modules/@glidejs/glide/src/assets/sass/glide.theme';

// Parciales
@import 'partials/logo';
@import 'partials/burger';
@import 'partials/search';
@import 'partials/finder';
@import 'partials/overlay';
@import 'partials/menu';
@import 'partials/menu-desktop';
@import 'partials/menu-suggested';
@import 'partials/home-shortcuts';
@import 'partials/menu-social';
@import 'partials/hero-header';
@import 'partials/breadcrumb';
@import 'partials/header';
@import 'partials/results';
@import 'partials/footer';
@import 'partials/is-rounded-animate';
@import 'partials/body';
@import 'partials/home';
@import 'partials/office';
@import 'partials/new';
@import 'partials/course-plan';
@import 'partials/counselors';
@import 'partials/authorities';
@import 'partials/profile';
@import 'partials/event';
@import 'partials/event-teaser';
@import 'partials/listings';
@import 'partials/sidebar';
@import 'partials/pagination';
@import 'partials/grant';
@import 'partials/base';
@import 'partials/contact-page';

// Components
@import 'components/links-pdf';
@import 'components/blocks';
@import 'components/block-link';
@import 'components/block-media';
@import 'components/block-sharing';
@import 'components/block-authorities';
@import 'partials/sharer-bar';
@import 'components/block-title';
@import 'components/block-dependencies';
@import 'components/block-dependencies-in-body';
@import 'components/block-maps';
@import 'partials/block-column';
@import 'partials/block-gallery';
@import 'components/block-slider';
@import 'components/block-form';
@import 'components/block-quote';
@import 'components/block-contact';
@import 'components/block-suggested';


// Fixes
a{
    transition: all 0.5s;
}

.title {

    &.is-green {
        color: $dark-cyan;
    }
}

body {
    padding-top: $gap * 8;
    
    @include desktop {
        padding-top: 11.4rem;
    }
}
.content .card figure.image {
    margin: 0;
    & img:after {
     background: $grey-dim;
     color: $grey-strong;

    }
}

.container {
    
    @include desktop {
        max-width: 70vw;
    }
}

.is-wrapper {

    @include desktop {
        padding-right: $gap * 4;
    }
}

.is-reverse-mobile {

    @include mobile {
        flex-direction: column-reverse;
        display: flex;
    }
}

.notification{
    margin: 0 !important;
    display: block;
    text-align: center;
    color: $white;
    font-weight: bold;
    width: 100%;
    z-index: -1;
    a {
        text-decoration: none !important;
    }
}