#header {
    
    .navbar-brand {

        > a.navbar-item {
            
            
            padding: 0;
            flex: none;
            margin: 0 0 0 auto;
            width: 100%;

            @include tablet {
                
                height: 3.375rem;
            }

            @include desktop {
                
                margin: 0 0 0 auto;
                height: 6.25rem;
            }
    
            &:hover {
                background-color: transparent;
            }
            svg{
                height: 6.25rem;
                margin: auto;
                #group-logo {
                
                    .group-glyph {
                        .path1 {
                            fill: #2C5F66;
                        }
                        .path2 {
                            fill: #DDC77A;
                        }
                    }
                    .group-text,
                    .group-tagline {
                        path {
                            fill: #2C5F66;
                        }
                    }
                }
            }
            
        }
    }

    &.is-fixed-top {

        .navbar-brand {

            > a.navbar-item {
                
                transition: height .3s, padding .3s;
                height: $gap * 3;
                
                svg{
                    margin-top: -8px;
                    .group-tagline {
                        display: none;   
                    }

                    @include desktop{
                        margin-top: -8px;
                    }
                }
            }
        }

        .logo-unc {
            display: none;
        }
    }

    .logo-unc {
        float: right;
        height: auto;
        max-height: 40px;
        max-width: 100%;
    }

    .logo-unc-mobile {
        float: right;
        width: 70%;
    }
}

.is-context-menu {

    #header {

        .navbar-brand {

            > a.navbar-item {
                svg{
                    
                    #group-logo {
                        .group-glyph {
                            .path1 {
                                fill: #FFFFFF;
                            }
                            .path2 {
                                fill: #FFFFFF;
                            }
                        }
                        .group-text,
                        .group-tagline {
                            path {
                                fill: #FFFFFF;
                            }
                        }
                    }
                }
            }
        }

        &.is-fixed-top {

            .navbar-brand {

                > a.navbar-item {
                   svg{
                    height: auto;
                       #group-logo{
                        .group-tagline {
                            visibility: collapse;
                                
                        }
                       }
                   }
                }
            }
        }
    }
}
