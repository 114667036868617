.block-quote{

    blockquote{
        background-color: $white;
        border: none;
        color: $black;
        font-family: $family-third;
        font-style: italic;
        font-weight: 300;
        max-width: $tablet;
        margin: 0 auto;
        padding: 2.83rem 0;
        text-align: center;

        .quote-author {

            font-weight: 500;
            
            @include tablet {
                text-align: right;
            }
        }
    }
}