.panels{
    .title{
        display:flex;
        align-items: center;
        h1{
            display: flex;
            color: $dark-cyan;

        }

        .image-inner{
            height: 25px;
            width: 25px;
            display: flex;
            float: left;
            margin-left: 0;
            margin-right: 10px;
        }
        .lock{
            background: url("../img/lock.svg") no-repeat center;
        }
        .registration{
            background: url("../img/registration.svg") no-repeat center;
        }
        .relevance{
            background: url("../img/relevance.svg") no-repeat center;
        }
        .requirements{
            background: url("../img/requirements.svg") no-repeat center;
        }
        .beneficiary{
            background: url("../img/beneficiary.svg") no-repeat center;
        }
        .document{
            background: url("../img/document.svg") no-repeat center;
        }
        .term{
            background: url("../img/term.svg") no-repeat center;
        }
    }

    .column{
        padding-top: 25px;
        padding-left: 25px;
        border-bottom: solid thin;
        border-color: $grey-dim;

        @include desktop{
            padding-top: 40px;
        }
    }
    .docs{
        border-color: transparent;
        padding-bottom: 40px;
    }
}
