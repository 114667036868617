.results {
    
    > .section {

        .container {

            > .title {
                border-bottom: 2px solid $grey-dim;
                padding: 0 0 $gap * 2;
                margin: $gap 0 $gap * 2;
        
                @include tablet {
                    margin-top: $gap * 2;
                }
            }

            .new-teaser-content {

                p {
                    display: none;
                } 
            }
        }
    }
}