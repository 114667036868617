.block-suggested_link {

    figure {
        background: #F8F8F8;
        margin: 0;
        padding: 0;
        position: relative;
        width: 100%;

        @include tablet {
            display: flex;
            align-items: flex-start;
        }

        &.no-image {
            justify-content: center;
            align-items: center;

            figcaption {
                text-align: center;

                .reference {
                    left: 0;
                }
            }
        }

        &:not(:last-child) {
            margin-bottom: 0;
        }

        > * {
            
            @include tablet {
                width: 50%;
            }
        }

        img {
            display: block;
        }
        
        figcaption {
            font-style: normal;
            padding: 10px;
            
            @include tablet {
                text-align: left;
            }

            .reference {
                position: absolute;
                left: 0;
                bottom: 0;
                background-color: var(--color);
                text-transform: uppercase;
                color: $white;
                padding: .3rem 2rem;
                font-weight: bolder;

                @include tablet {
                    position: relative;
                    top: -10px;
                    left: -10px;
                }
            }

            h3 {
                font-weight: bold;
                margin: 0;

                a {
                    color: $black;
                }
            }
        }
    }
}