#header {
    background: $white;
    box-shadow: 0 3px 6px rgba($dark, .16);
    transition: height .3s, padding .3s;
    height: auto;
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    z-index: 9;

    
    @include desktop {
        height: 11.4rem;
        background: $white url(../img/bg-header.svg) no-repeat bottom right;
    }

    &.is-fixed-top {
        height: auto;
        
        @include desktop {
            height: 6.063rem;
        }

        .header-social {
            display: none;
        }
    }

    & > .container {
        height: 100%;

        & > .columns {
            justify-content: space-between;
            width: 100%;
            height: 100%;
            
            & > .column {
                align-self: center;
                width: 33%;
    
                @include desktop {
                    padding: 0;
                }
            }

            .main-menu {
                display: flex;
                align-items: center;
            }
        }
    }
}