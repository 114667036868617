.block-contact {
  ul {
    display: flex;
    flex-direction: column;

    li {
      display: flex;
      flex-flow: row;
      .card-email {
        width: 95%;
        overflow: hidden;
      }
      a {
        padding: 2px;
        color: $black;
        &:hover {
          background: transparent;
        }
      }
      &[class^="card-"] .icon {
        border-right: 1px solid $grey-dim;
        margin-right: 4px;
        padding: 2px;
        color: $green;
      }
    }

    > * {
      margin-bottom: $gap;

      &:last-child {
        margin-bottom: 0;
      }
    }

    .social-links {
      flex-direction: row;

      li {
        margin-bottom: 0;

        a {
          margin-right: $gap / 2;
          height: $gap * 2;
          width: auto;

          i {
            margin: 0;

            &:after {
              display: none;
            }
          }
        }
      }
    }
  }

  ul.social-links {
    border: transparent 0px solid;
    padding: 0;
    .facebook {
      a {
        i {
          background: url(../img/facebook-green.svg) no-repeat center;
          background-size: contain;
        }
      }
    }

    .twitter {
      a {
        i {
          background: url(../img/twitter-green.svg) no-repeat center;
          background-size: contain;
        }
      }
    }

    .youtube {
      a {
        i {
          background: url(../img/youtube-green.svg) no-repeat center;
          background-size: contain;
          width: 2.4rem;
        }
      }
    }

    .instagram {
      a {
        i {
          background: url(../img/instagram-green.svg) no-repeat center;
          background-size: contain;
        }
      }
    }

    .linkedin {
      a {
        i {
          background: url(../img/linkedin-green.svg) no-repeat center;
          background-size: contain;
        }
      }
    }

    .scielo {
      a {
        i {
          background: url(../img/scielo.ico) no-repeat center;
          background-size: contain;
        }
      }
    }
  }
}
