.hero {
    position: relative;
    height: auto;
    width: 100%;
    margin-bottom: 0;

    &.is-hero-image {
        margin-bottom: $gap * 2;

        @include desktop {
            margin-bottom: $gap * 4;
        }
    }

    .hero-body {

        .title {
            padding-bottom: .5rem;
            @include desktop {
                font-size: 3rem;
                padding-bottom: 1.5rem;
            }
        }

        .byline {
            align-items: center;
            color: $grey-dark;
            display: flex;
            height: 100%;
            font-size: $gap;

            @include desktop{
                font-size: $gap +2;
            }
        }
    }
}

.office, .career, .subject, .landing {
    
    .hero {

        &.is-hero-image {

            .hero-body {
                position: absolute;
                padding: 3rem 1.5rem;

                .title {
                    color: $white;
                    
                    @include desktop {
                        margin-top: 2rem;
                    }
                }
    
                .description {
                    color: $white;
                    margin-top: .5rem;
    
                    @include desktop {
                        width: 50%;
                    }
                }
            }

            .hero-head {

                .image {
                    width: 100%;
                    height: auto;
    
                    &:before {
                        content: " ";
                        background-color: rgba(0, 50, 0, 0.4);
                        display: block;
                        width: 100%;
                        height: 100%;
                        position: absolute;
                    }
                }
            }
        }

        &>div {
            width: 100%;
            height: 100%;
            overflow: hidden;
            display: flex;
            justify-content: center;
            align-items: center;
        }

        .hero-body {
            padding: 1.5rem;

            .recordnumber{
                display:flex;
                color: white;

                @include desktop{
                    font-size: 1.6rem;
                }
                h1, h2{
                    border-bottom: solid thin;
                    border-color: $grey-dim;
                    align-items: center
                }
                h2 strong {
                    font-size: 2rem;
                    color: white;
                }
            }
            .title {
                margin-bottom: .5rem;

                @include desktop {
                    margin-top: 2rem;
                    font-size: 3rem;
                }
            }
            .title.position{
                margin-top: .5rem;
                margin-bottom: 1rem;
                justify-content: center;
                @include desktop {
                    margin-top: 1.5rem;
                    margin-bottom: 2rem;
                    font-size: 4rem;

                }
            }
            .call_subject{
                color: white;
                margin-bottom: 1rem;

                @include desktop{
                    font-size: 1.6rem;
                }
            }
            .department{
                color: white;
                margin-bottom: 1rem;

                @include desktop{
                    font-size: 1.6rem;
                }
            }
            h2{
                font-weight: 300;
            }
            h4{
                font-weight: 600;
            }
        }
    }
}

.new {

    .description {
        font-size: 1.4rem;
        font-weight: bold;
    }
}