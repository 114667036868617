.pagination {
    margin-top: $gap * 2;
    padding: $gap * 2;

    a {
        color: rgba($dark-cyan, .5);
        background: none;
        border: none;
        font-weight: bold;
        text-transform: uppercase;

        &:hover {
            color: $dark-cyan;
        }
    }

    ul {

        li {

            a {

                &.is-current {
                    background: none;
                    color: $dark-cyan;
                }
            }
        }
    }
}