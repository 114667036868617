[id*="map-"] {
    height: 20rem;
    width: 100%;

    .gm-style {

        .gm-style-iw-c {
            background: $dark-cyan;
            background-size: contain;
            border-radius: 0;
            box-shadow: none;
            display: flex;
            overflow: visible;
            padding: 0;
            min-height: 105px;
            min-width: 180px;
        }
        
        .gm-style-iw-t {

            &:after {
                background: $dark-cyan;
            }
            
            .gm-style-iw-d {
                align-items: center;
                display: flex;
                overflow: visible !important;
                justify-content: center;
                width: 100%;
                
                & > div {
                    box-sizing: border-box;
                    color: white;
                    padding: 10px;
                    width: 100%;
                    
                    h3 {
                        
                        font-weight: bold;
                    }
                }
            }
            
            button {
                background: white !important;
                border-radius: 50% !important;
                right: -10px !important;
                top: -10px !important;
                height: 20px !important;
                width: 20px !important;
                
                img {
                    margin: 3px !important;
                }
            }
        }
    }
}

.footer-map {
    display: none;

    @include tablet {
        display: block;
    }

    [id*="map-"] {

        @include tablet {
            height: 32rem;
        }

        @include fullhd {
            height: 600px;
        }

        .gm-style {

            .gm-style-iw-c {
                
                @include tablet {
                    background: url('../img/bg-infowindow.svg') no-repeat center;
                    background-size: contain;
                    transform: translate(-50px, -22px);
                    min-height: 105px;
                    min-width: 280px;
                    max-height: 105px !important;
                    max-width: 280px !important;
                }
            }

            .gm-style-iw-t {

                &:after {
                    
                    @include tablet {
                        display: none;
                    }
                }
                
                .gm-style-iw-d {
                    
                    & > div {
                        
                        @include tablet {
                            padding: 10px 10px 10px 110px;
                        }
                        
                        h3 {
                            font-weight: bold;
                        }
                    }
                }
            }
        }
    }
}

.home {

    .footer-map {
        display: block;
    }
}

// Fix temporal
#map-325 {
    background: url(../img/mapa-temporal.png) no-repeat center;
    background-size: cover;
}