#finder {
    width: 100%;
    justify-content: center;
    align-items: center;
    
    &.is-active {
        display: flex;
        opacity: 1;
    }

    form {
        height: 50vh;
        padding: 0 $gap;

        @include tablet {
            padding: 0 $gap * 2;
        }
        
        @include desktop {
            padding: 0 $gap * 4;
        }
        .search-box {
            margin-bottom: $gap * 2;
        }
        .is-fullwidth {
            position: relative;

            .search-term {
                border-left: 1px solid $white;
                margin-left: $gap / 2;
            }
            input {
                background: transparent;
                border: none;
                border-radius: 0;
                box-shadow: none;
                padding: $gap / 2;
                color: $white;
                font-size: $size-3;

                @include tablet {
                    float: right;
                }

    
                &::placeholder {
                    color: $white;
                }
            }
        }

    }

    p, ul, li {
        color: $white;
        margin-bottom: $gap;
    } 

    ul {

        li {
            display: flex;

            &:before {
                background: url(../img/search-arrow.svg) no-repeat center;
                background-size: contain;
                content: " ";
                display: none;
                margin-right: $gap / 2;
                height: 16px;
                width: 16px;

                @include tablet {
                    display: block;
                }
            }
            a {
                color: white;
                &:hover {
                    color: $blue;
                }

            }
        }
    }
}
