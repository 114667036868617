.body {
  // Description
  .description {
    margin-bottom: $gap * 3;
  }

  [class*="block-"] {
    max-height: 8000vh;
    opacity: 1;
    transition: 0.5s ease-out;
    transform-origin: top left;
    padding: 1rem 0;

    &.is-closed {
      max-height: 0;
      opacity: 0;
      transform: scale(0);
    }

    > * {
      max-height: 100%;
    }
  }
  .block-paragraph {
    @include desktop {

    }
    hr {
      clear: both;
    }
  }

  h1,
  h2,
  h3,
  h4,
  h5 {
    font-family: $title-family;
    font-weight: normal;
  }
}
