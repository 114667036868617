.card{
    height: 100%;

.card-image{
    .image{
        img{
            height: 17.4375rem;
            object-fit: cover;
        }
    }

    .image-inner{
        position: absolute;
        height: 5rem;
        width: 5rem;
        display: flex;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
    .lock{
        background: url("../img/lock-white.svg") no-repeat center;
    }
    .reference {
        position: absolute;
        left: 0;
        bottom: 0;
        background-color: var(--color);
        text-transform: uppercase;
        color: $white;
        padding: .3rem 2rem;
        font-weight: bolder;
    }
}

.card-content {

    .image-inner{
        height: 1.5rem;
        width: 1.5rem;
        display: flex;
        float: left;
        margin-right: 10px;
    }
    .lock{
        background: url("../img/lock-grey.svg") no-repeat center;
    }
    .unlock{
        background: url("../img/unlock.svg") no-repeat center;
    }
}


}