@keyframes slidein {
    
    from {
        display: none;
        opacity: 0;
    }
    
    to {
        display: flex; 
        opacity: 1;
    }
}

@keyframes slideout {
    0% {
        display: flex;
        opacity: 1;
    }
    
    90% {
        opacity: .1;
    }
    
    100% {
        display: none;
        opacity: 0;
    }
}