.event-teaser {
    
    .event-teaser-header {
        background-color: var(--color);
        height: 6.5rem;

        @include desktop {
            height: 13rem;
        }

        .weekday {
            background-color: $white;
            font-size: 0.7625rem;
            margin: 0;
            max-width: 80%;
            text-align: center;
            text-transform: uppercase;
            font-weight: bolder;
            color: var(--color);

            @include desktop {
                font-size: 1rem;
                max-width: 60%;
            }
        }

        .day {
            font-size: 3.0625rem;
            font-weight: lighter;
            line-height: 3.5rem;
            text-align: center;
            color: $white;
            
            @include desktop {
                font-size: 6.25rem;
                line-height: 6.75rem;
                margin: 1rem 0 0;
            } 
        }

        .month {
            text-align: center;
            text-transform: uppercase;
            color: $white;
            font-size: 1rem;
            font-weight: lighter;
            padding-bottom: 0.85rem;

            @include desktop {
                font-size: 1.5rem;
            }
        }
    }


    .event-teaser-content {
        .place {
            background: url(../img/event-map-small.svg) no-repeat center left;
            padding-left: $gap + 3;
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;

            @include desktop {
                background: url(../img/event-map.svg) no-repeat center left;
                padding: 3px 0 3px $gap * 2;
                white-space: initial;
            }
        }
        
        .clock {
            background: url(../img/event-clock-small.svg) no-repeat center left;
            padding-left: $gap + 3;

            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;

            @include desktop {
                background: url(../img/event-clock.svg) no-repeat center left;
                padding-left:$gap * 2;
                white-space: initial;
            }
        }
    }
}