@font-face {
    font-family: 'Roboto';
    src: url('../fonts/Roboto-MediumItalic.eot');
    src: url('../fonts/Roboto-MediumItalic.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Roboto-MediumItalic.woff2') format('woff2'),
        url('../fonts/Roboto-MediumItalic.woff') format('woff'),
        url('../fonts/Roboto-MediumItalic.ttf') format('truetype');
    font-weight: 500;
    font-style: italic;
}

@font-face {
    font-family: 'Roboto';
    src: url('../fonts/Roboto-Regular.eot');
    src: url('../fonts/Roboto-Regular.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Roboto-Regular.woff2') format('woff2'),
        url('../fonts/Roboto-Regular.woff') format('woff'),
        url('../fonts/Roboto-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Roboto';
    src: url('../fonts/Roboto-Thin.eot');
    src: url('../fonts/Roboto-Thin.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Roboto-Thin.woff2') format('woff2'),
        url('../fonts/Roboto-Thin.woff') format('woff'),
        url('../fonts/Roboto-Thin.ttf') format('truetype');
    font-weight: 100;
    font-style: normal;
}

@font-face {
    font-family: 'Rubik';
    src: url('../fonts/Rubik-LightItalic.eot');
    src: url('../fonts/Rubik-LightItalic.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Rubik-LightItalic.woff2') format('woff2'),
        url('../fonts/Rubik-LightItalic.woff') format('woff'),
        url('../fonts/Rubik-LightItalic.ttf') format('truetype');
    font-weight: 300;
    font-style: italic;
}

@font-face {
    font-family: 'Roboto';
    src: url('../fonts/Roboto-Medium.eot');
    src: url('../fonts/Roboto-Medium.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Roboto-Medium.woff2') format('woff2'),
        url('../fonts/Roboto-Medium.woff') format('woff'),
        url('../fonts/Roboto-Medium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'Roboto';
    src: url('../fonts/Roboto-Italic.eot');
    src: url('../fonts/Roboto-Italic.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Roboto-Italic.woff2') format('woff2'),
        url('../fonts/Roboto-Italic.woff') format('woff'),
        url('../fonts/Roboto-Italic.ttf') format('truetype');
    font-weight: normal;
    font-style: italic;
}

@font-face {
    font-family: 'Rubik';
    src: url('../fonts/Rubik-Light.eot');
    src: url('../fonts/Rubik-Light.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Rubik-Light.woff2') format('woff2'),
        url('../fonts/Rubik-Light.woff') format('woff'),
        url('../fonts/Rubik-Light.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'Rubik';
    src: url('../fonts/Rubik-Regular.eot');
    src: url('../fonts/Rubik-Regular.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Rubik-Regular.woff2') format('woff2'),
        url('../fonts/Rubik-Regular.woff') format('woff'),
        url('../fonts/Rubik-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Rubik';
    src: url('../fonts/Rubik-Italic.eot');
    src: url('../fonts/Rubik-Italic.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Rubik-Italic.woff2') format('woff2'),
        url('../fonts/Rubik-Italic.woff') format('woff'),
        url('../fonts/Rubik-Italic.ttf') format('truetype');
    font-weight: normal;
    font-style: italic;
}

@font-face {
    font-family: 'Roboto';
    src: url('../fonts/Roboto-Bold.eot');
    src: url('../fonts/Roboto-Bold.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Roboto-Bold.woff2') format('woff2'),
        url('../fonts/Roboto-Bold.woff') format('woff'),
        url('../fonts/Roboto-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'Rubik';
    src: url('../fonts/Rubik-BoldItalic.eot');
    src: url('../fonts/Rubik-BoldItalic.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Rubik-BoldItalic.woff2') format('woff2'),
        url('../fonts/Rubik-BoldItalic.woff') format('woff'),
        url('../fonts/Rubik-BoldItalic.ttf') format('truetype');
    font-weight: bold;
    font-style: italic;
}

@font-face {
    font-family: 'Rubik';
    src: url('../fonts/Rubik-BlackItalic.eot');
    src: url('../fonts/Rubik-BlackItalic.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Rubik-BlackItalic.woff2') format('woff2'),
        url('../fonts/Rubik-BlackItalic.woff') format('woff'),
        url('../fonts/Rubik-BlackItalic.ttf') format('truetype');
    font-weight: 900;
    font-style: italic;
}

@font-face {
    font-family: 'Roboto';
    src: url('../fonts/Roboto-ThinItalic.eot');
    src: url('../fonts/Roboto-ThinItalic.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Roboto-ThinItalic.woff2') format('woff2'),
        url('../fonts/Roboto-ThinItalic.woff') format('woff'),
        url('../fonts/Roboto-ThinItalic.ttf') format('truetype');
    font-weight: 100;
    font-style: italic;
}

@font-face {
    font-family: 'Roboto';
    src: url('../fonts/Roboto-Light.eot');
    src: url('../fonts/Roboto-Light.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Roboto-Light.woff2') format('woff2'),
        url('../fonts/Roboto-Light.woff') format('woff'),
        url('../fonts/Roboto-Light.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'Roboto';
    src: url('../fonts/Roboto-LightItalic.eot');
    src: url('../fonts/Roboto-LightItalic.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Roboto-LightItalic.woff2') format('woff2'),
        url('../fonts/Roboto-LightItalic.woff') format('woff'),
        url('../fonts/Roboto-LightItalic.ttf') format('truetype');
    font-weight: 300;
    font-style: italic;
}

@font-face {
    font-family: 'Rubik';
    src: url('../fonts/Rubik-Bold.eot');
    src: url('../fonts/Rubik-Bold.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Rubik-Bold.woff2') format('woff2'),
        url('../fonts/Rubik-Bold.woff') format('woff'),
        url('../fonts/Rubik-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'Rubik';
    src: url('../fonts/Rubik-MediumItalic.eot');
    src: url('../fonts/Rubik-MediumItalic.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Rubik-MediumItalic.woff2') format('woff2'),
        url('../fonts/Rubik-MediumItalic.woff') format('woff'),
        url('../fonts/Rubik-MediumItalic.ttf') format('truetype');
    font-weight: 500;
    font-style: italic;
}

@font-face {
    font-family: 'Rubik';
    src: url('../fonts/Rubik-Medium.eot');
    src: url('../fonts/Rubik-Medium.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Rubik-Medium.woff2') format('woff2'),
        url('../fonts/Rubik-Medium.woff') format('woff'),
        url('../fonts/Rubik-Medium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'Roboto';
    src: url('../fonts/Roboto-Black.eot');
    src: url('../fonts/Roboto-Black.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Roboto-Black.woff2') format('woff2'),
        url('../fonts/Roboto-Black.woff') format('woff'),
        url('../fonts/Roboto-Black.ttf') format('truetype');
    font-weight: 900;
    font-style: normal;
}

@font-face {
    font-family: 'Roboto';
    src: url('../fonts/Roboto-BlackItalic.eot');
    src: url('../fonts/Roboto-BlackItalic.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Roboto-BlackItalic.woff2') format('woff2'),
        url('../fonts/Roboto-BlackItalic.woff') format('woff'),
        url('../fonts/Roboto-BlackItalic.ttf') format('truetype');
    font-weight: 900;
    font-style: italic;
}

@font-face {
    font-family: 'Roboto';
    src: url('../fonts/Roboto-BoldItalic.eot');
    src: url('../fonts/Roboto-BoldItalic.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Roboto-BoldItalic.woff2') format('woff2'),
        url('../fonts/Roboto-BoldItalic.woff') format('woff'),
        url('../fonts/Roboto-BoldItalic.ttf') format('truetype');
    font-weight: bold;
    font-style: italic;
}

@font-face {
    font-family: 'Rubik';
    src: url('../fonts/Rubik-Black.eot');
    src: url('../fonts/Rubik-Black.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Rubik-Black.woff2') format('woff2'),
        url('../fonts/Rubik-Black.woff') format('woff'),
        url('../fonts/Rubik-Black.ttf') format('truetype');
    font-weight: 900;
    font-style: normal;
}

@font-face {
    font-family: 'Montserrat';
    src: url('../fonts/Montserrat-Light.woff2') format('woff2'),
        url('../fonts/Montserrat-Light.woff') format('woff'),
        url('../fonts/Montserrat-Light.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'Montserrat';
    src: url('../fonts/Montserrat-SemiBold.woff2') format('woff2'),
        url('../fonts/Montserrat-SemiBold.woff') format('woff'),
        url('../fonts/Montserrat-SemiBold.ttf') format('truetype');
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: 'Montserrat';
    src: url('../fonts/Montserrat-Black.woff2') format('woff2'),
        url('../fonts/Montserrat-Black.woff') format('woff'),
        url('../fonts/Montserrat-Black.ttf') format('truetype');
    font-weight: 900;
    font-style: normal;
}

@font-face {
    font-family: 'Montserrat';
    src: url('../fonts/Montserrat-BoldItalic.woff2') format('woff2'),
        url('../fonts/Montserrat-BoldItalic.woff') format('woff'),
        url('../fonts/Montserrat-BoldItalic.ttf') format('truetype');
    font-weight: bold;
    font-style: italic;
}

@font-face {
    font-family: 'Montserrat';
    src: url('../fonts/Montserrat-Italic.woff2') format('woff2'),
        url('../fonts/Montserrat-Italic.woff') format('woff'),
        url('../fonts/Montserrat-Italic.ttf') format('truetype');
    font-weight: normal;
    font-style: italic;
}

@font-face {
    font-family: 'Montserrat';
    src: url('../fonts/Montserrat-Bold.woff2') format('woff2'),
        url('../fonts/Montserrat-Bold.woff') format('woff'),
        url('../fonts/Montserrat-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'Montserrat';
    src: url('../fonts/Montserrat-Regular.woff2') format('woff2'),
        url('../fonts/Montserrat-Regular.woff') format('woff'),
        url('../fonts/Montserrat-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Montserrat';
    src: url('../fonts/Montserrat-ExtraBoldItalic.woff2') format('woff2'),
        url('../fonts/Montserrat-ExtraBoldItalic.woff') format('woff'),
        url('../fonts/Montserrat-ExtraBoldItalic.ttf') format('truetype');
    font-weight: 800;
    font-style: italic;
}

@font-face {
    font-family: 'Montserrat';
    src: url('../fonts/Montserrat-Thin.woff2') format('woff2'),
        url('../fonts/Montserrat-Thin.woff') format('woff'),
        url('../fonts/Montserrat-Thin.ttf') format('truetype');
    font-weight: 100;
    font-style: normal;
}

@font-face {
    font-family: 'Montserrat';
    src: url('../fonts/Montserrat-ExtraLightItalic.woff2') format('woff2'),
        url('../fonts/Montserrat-ExtraLightItalic.woff') format('woff'),
        url('../fonts/Montserrat-ExtraLightItalic.ttf') format('truetype');
    font-weight: 200;
    font-style: italic;
}

@font-face {
    font-family: 'Montserrat';
    src: url('../fonts/Montserrat-MediumItalic.woff2') format('woff2'),
        url('../fonts/Montserrat-MediumItalic.woff') format('woff'),
        url('../fonts/Montserrat-MediumItalic.ttf') format('truetype');
    font-weight: 500;
    font-style: italic;
}

@font-face {
    font-family: 'Montserrat';
    src: url('../fonts/Montserrat-Medium.woff2') format('woff2'),
        url('../fonts/Montserrat-Medium.woff') format('woff'),
        url('../fonts/Montserrat-Medium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'Montserrat';
    src: url('../fonts/Montserrat-ExtraLight.woff2') format('woff2'),
        url('../fonts/Montserrat-ExtraLight.woff') format('woff'),
        url('../fonts/Montserrat-ExtraLight.ttf') format('truetype');
    font-weight: 200;
    font-style: normal;
}

@font-face {
    font-family: 'Montserrat';
    src: url('../fonts/Montserrat-BlackItalic.woff2') format('woff2'),
        url('../fonts/Montserrat-BlackItalic.woff') format('woff'),
        url('../fonts/Montserrat-BlackItalic.ttf') format('truetype');
    font-weight: 900;
    font-style: italic;
}

@font-face {
    font-family: 'Montserrat';
    src: url('../fonts/Montserrat-ThinItalic.woff2') format('woff2'),
        url('../fonts/Montserrat-ThinItalic.woff') format('woff'),
        url('../fonts/Montserrat-ThinItalic.ttf') format('truetype');
    font-weight: 100;
    font-style: italic;
}

@font-face {
    font-family: 'Montserrat';
    src: url('../fonts/Montserrat-SemiBoldItalic.woff2') format('woff2'),
        url('../fonts/Montserrat-SemiBoldItalic.woff') format('woff'),
        url('../fonts/Montserrat-SemiBoldItalic.ttf') format('truetype');
    font-weight: 600;
    font-style: italic;
}

@font-face {
    font-family: 'Montserrat';
    src: url('../fonts/Montserrat-LightItalic.woff2') format('woff2'),
        url('../fonts/Montserrat-LightItalic.woff') format('woff'),
        url('../fonts/Montserrat-LightItalic.ttf') format('truetype');
    font-weight: 300;
    font-style: italic;
}

@font-face {
    font-family: 'Montserrat';
    src: url('../fonts/Montserrat-ExtraBold.woff2') format('woff2'),
        url('../fonts/Montserrat-ExtraBold.woff') format('woff'),
        url('../fonts/Montserrat-ExtraBold.ttf') format('truetype');
    font-weight: 800;
    font-style: normal;
}