.block-column {
    columns: auto 1;

    &.block-column-2 {

        @include tablet {
            columns: auto 2;
        }
    }

    &.block-column-3 {

        @include tablet {
            columns: auto 3;
        }
    }

    &.block-column-4 {

        @include tablet {
            columns: auto 4;
        }
    }
}