.block-form {
    transition: all all.5s;
    
    .modal {
        max-height: 0;
        animation-duration: .5s;
        animation-name: slideout;
        
        &.is-active {
            animation-name: slidein;
            max-height: 100vh;
        }

        .modal-content {
            display: flex;
            align-items: center;
            justify-content: center;
            
            iframe {
                height: 80vh;
            }
        }
    }
}