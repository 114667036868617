// Breadcrumb
.breadcrumb {
    background: none;
    color: $grey-dark;
    padding: $gap 1.5rem;
    margin-bottom: 0 !important;
    @include desktop {
      padding: $gap 0;
    }
  
    ul {
  
      li {
  
        &:before {
          color: $grey-dark;
          content: ">";
        }

        &:first-child{
            
            &:before {
                display:none;
            }
        }
        
        a {
          color: $grey-dark;

        }
        
        &.is-active {
          font-weight: bold;
  
          a {
            color: $grey-dark;
          }
        }    
      } 
    }
  }