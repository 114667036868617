.block-dependencies {
    padding: $gap 0;
    border: solid thin;
    border-left: 0;
    border-right: 0;
    border-color: $grey-dim;

    a {
        border: 1px solid $grey;
        
        display: block;
        margin: 0.5rem 0;
        text-align: center;
        padding: 0.4rem;
    }
}