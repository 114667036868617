#overlay {
    
    @include desktop {
        background: $dark-cyan url(../img/bg-overlay.svg) no-repeat left bottom;
        background-size: contain;
    }
    
    &.is-active {
        display: block;
        opacity: 1;
    }

    &:after {
        
        @include desktop {
            background: url(../img/bg-overlay-waves.svg) no-repeat top right;
            content: "";
            bottom: -1%;
            top: -1%;
            right: -5%;
            position: absolute;
            height: 102%;
            width: 100%;
            z-index: -1;
        }
    }

    #close-overlay {
        display: none;

        @include desktop {
            background-color: transparent;
            display: block;
            height: 100%;
            width: 42%;
            top: 0;
            right: 0;
            position: fixed;
            z-index: -2;
        }
    }
}