.sidebar {

    .title {
        text-transform: none;
        
        color: $dark-cyan;
        margin-bottom: $gap;
    }

    .title.tribunal{
        
        letter-spacing: 0.5px;
    }

    [class*="block-"] {

        @include tablet {
            margin-bottom: 4* $gap;
        }
        h2{
            
            font-weight: 300;
            letter-spacing: 0.2px;
        }
        h4 {
            
            a {
                color: $dark-cyan;
            }
        }

        h4.tribunal{
            
            font-weight: 500;
            letter-spacing: 0.5px;
            padding-bottom: 1rem;
        }
        i {

            svg {
                fill: $dark-cyan;
            }
        }
    }
}