.authorities {
  background: url(../img/bg-authorities.svg) no-repeat;
  background-position: left top;
  background-size: contain;

  .body {
    .column {
      display: flex;
      align-items: center;
    }
  }

  .authority {
    padding-right: $gap * 2;

    img {
      border-radius: 50%;
      max-width: inherit;
      height: auto;
      width: 128px;
    }
  }

  h4 {
    color: $grey-strong;
    font-weight: bolder;
    margin-bottom: 0;
  }

  .position {
    color: $grey-strong;
    display: block;
    
    text-transform: uppercase;
  }

  .office {
    color: $dark-cyan;
    display: block;
    
  }

  .level-1, .level-2 {
    img {
      @include tablet {
        width: 288px;
      }
    }
  }

  .level-3 {
    justify-content: center;

    img {
      @include tablet {
        width: 248px;
      }
    }
  }
  .level-4 {
    img {
      @include tablet {
        width: 208px;
      }
    }
  }
  .level-6 {
    justify-content: center;

    img {
      @include tablet {
      }
    }
  }
}
