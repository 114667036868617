// Colores
$white: #ffffff;
$grey-thin: #ededed;
$grey-dim: #cecece;
$grey: #a2a2a2;
$grey-dark: #707070;
$grey-strong: #363636;
$black: #000000;
$black-dim: #0a0a0a;
$green: #205650;
$dark-cyan: #2c5f66;
$blue-sky: #3392e5;
$yellow: #ddcf82;
$gold: #ddcf82;
$orange: #ff7f00;

// Fonts
$family-primary: "Roboto", sans-serif;
$family-secondary: "Montserrat", sans-serif;
$family-third: "Rubik", sans-serif;
$body-size: 13px;
$body-font-size: 16px;
$body-color: $black-dim;

$body-family: $family-primary;
$title-family: $family-secondary;
$subtitle-family: $family-secondary;
$weight-semibold: 700;
// Sizes
$desktop: 980px;

// Heights
$standard-height: 25rem;

// Margin
$gap: $body-size;

// Padding
$section-padding: 0 1.5rem 1.6875rem;
$section-padding-large: 0 1.5rem 4.6875rem;

// Navbar
$navbar-item-color: $white;
$navbar-item-hover-color: $yellow;
$navbar-item-active-color: $yellow;

$navbar-item-hover-background-color: transparent;
$navbar-item-active-background-color: transparent;

$navbar-bottom-box-shadow-size: 0;
$navbar-box-shadow-color: transparent;
$navbar-dropdown-border-top: 0 solid transparent;
$navbar-box-shadow-size: 0;

$navbar-divider-height: 0;
$navbar-dropdown-arrow: $white;

$navbar-dropdown-background-color: transparent;
$navbar-dropdown-item-active-color: $yellow;
$navbar-dropdown-item-hover-color: $yellow;
$navbar-dropdown-item-hover-background-color: transparent;

// animations for the collapsible Arrows
$degree: 180deg;

//notifications
$notification-background-color: $orange;
$notification-radius: 0;
$notification-padding: 0.3rem;

// Hero
$hero-body-padding: 1.5rem;