.social {
    display: flex;
    justify-content: flex-end;

    li {
        background-size: contain !important;
        transition: background .5s ease-out;
        -webkit-transition: background .5s ease-out;
        -moz-transition: background .5s ease-out;
        -o-transition: background .5s ease-out;
        width: $gap * 3;
        height: $gap * 2.5;
        margin: 0 $gap 0 0;

        &:last-child {
            margin: 0;
        }

        &.youtube {
            background: url(../img/youtube-green.svg) no-repeat center;
            width: 31.25px;

            &:hover {
                background: url(../img/youtube.svg) no-repeat center;
            }
        }

        &.twitter {
            background: url(../img/twitter-green.svg) no-repeat center;

            &:hover {
                background: url(../img/twitter.svg) no-repeat center;
            }
        }

        &.instagram {
            background: url(../img/instagram-green.svg) no-repeat center;
            border-radius: 50%;

            &:hover {
                background-image: url(../img/instagram.svg), url(../img/instagram-bg.png);
                background-repeat: no-repeat, no-repeat;
                background-position: center, center;
            }
        }

        &.facebook {
            background: url(../img/facebook-green.svg) no-repeat center;

            &:hover {
                background: url(../img/facebook.svg) no-repeat center;
            }
        }
        
        &.linkedin {
            background: url(../img/linkedin-green.svg) no-repeat center;

            &:hover {
                background: url(../img/linkedin.svg) no-repeat center;
            }
        }

        a {
            display: flex;
            width: 100%;
            height: 100%;
        } 
    }
}



.is-white {

    .social {

        li {

            &.youtube {
                background: url(../img/youtube-white.svg) no-repeat center;
                width: 31.25px;
            }

            &.twitter {
                background: url(../img/twitter-white.svg) no-repeat center;
            }

            &.instagram {
                background: url(../img/instagram-white.svg) no-repeat center;
            }

            &.facebook {
                background: url(../img/facebook-white.svg) no-repeat center;

            }

            &.linkedin {
                background: url(../img/linkedin-white.svg) no-repeat center;
            }
        }
    }
}