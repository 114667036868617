.sharer-bar {
    align-items: center;
    display: flex;
    height: 100%;

    h6 {
        color: $grey-dark;
        font-family: $family-secondary;
        
        text-transform: uppercase;
        margin-right: $gap - 2px;
    }

    ul {
        display: flex;

        li {
            background-size: contain !important;
            width: 24px;
            height: 24px;
            margin: 0 0.25rem;

            &.twitter {
                background: url(../img/twitter.svg) no-repeat center;
            }

            &.whatsapp {
                background: url(../img/whatsapp.svg) no-repeat center;
                margin-top: -2px;
                height: 28px;
                width: 28px;
            }

            &.instagram {
                background-image: url(../img/instagram.svg), url(../img/instagram-bg.png);
                background-repeat: no-repeat, no-repeat;
                background-position: center, center;
                border-radius: 50%;
            }

            &.facebook {
                background: url(../img/facebook.svg) no-repeat center;
            }

            &.link {
                background: $grey-dim;
                border-radius: 50%;

                a {
                    background: url(../img/link.svg) no-repeat center;
                    height: 16px;
                    width: 16px;
                    margin: 4px;
                }
            }

            &.linkedin {
                background: url(../img/linkedin.svg) no-repeat center;
                height: 22px;
                width: 22px;
            }

            a {
                display: flex;
                height: 100%;
                width: 100%;

                &.is-mobile-link {

                    @include desktop {
                        display: none;
                    }
                }

                &.is-desktop-link {
                    display: none;

                    @include desktop {
                        display: block;
                    }
                }
            }
        }
    }
}

.block-sharing {

    .sharer-bar {

        ul {
            align-items: center;
            justify-content: right;
            width: 100%;
        }
    }
}