a[href*=".pdf"] {
  border-bottom: 1px solid $dark-cyan;
  color: $black;
  display: inline-block;
  padding: $gap/4 2px $gap/4 0;
  margin: 0 $gap;
  position: relative;

  &:after {
    background: url(../img/bg-link-pdf.svg) no-repeat center;
    background-size: contain;
    content: " ";
    position: absolute;
    right: -$gap * 2.5;
    height: $gap * 2;
    width: $gap * 2;
  }
}

.menu-list {
  a[href*=".pdf"] {
    border-bottom: none;
    margin-left: $gap * 2;
    margin-bottom: 0;

    &:after {
      background-position: center left;
      left: -$gap * 2;
      right: inherit;
      height: $gap + $gap * 0.5;
    }
  }
}
