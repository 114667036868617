.footer-map {
  [class*="block-"] {
    margin-bottom: 0;
  }
}
div.rich-text {
  overflow: hidden;
}
div.rich-text > img.richtext-image.left {
  @include desktop {
    margin-right: $gap * 3;
  }
}
img.richtext-image.left {
  float: left;
  margin-right: $gap;
  margin-bottom: $gap;

}
img.richtext-image.right {
  float: right;
  margin-left: $gap;
  margin-bottom: $gap;

}
img.rich-text-image.left:after {
  content: "invisible";
  display: block;
  clear: left;
  margin-bottom: $gap;
}
img.rich-text-image.right:after {
  content: "invisible";
  display: block;
  clear: right;
  margin-bottom: $gap;
}
div.rich-text > img.richtext-image.right {
  @include desktop {
    margin-left: $gap * 3;
  }
}

.content .tabs:not(:last-child) {
  margin-bottom: 0;
}

.content .tabcontent {
  padding: 1rem;
  display: none;
}

.content .tabcontent.is-active {
  display: block;
}